.footer-container {
  position: absolute;
  left: 45%;
  bottom: 7%;
  overflow: hidden;
}

.icon-container:hover {
  cursor: pointer;
}

@media (max-width: 950px) {
  .footer-container {
    left: 27%;
    bottom: 5%;
  }
}
