:root {
  --orgb: #090c10;
  --mcb1: #1e2836;
  --mcb2: #0f141b;
  --mcb3: #0c1015;
  --mcb4: #0a0d12;
}

body {
  margin: 0px;
  padding: 0px;
  font-family: "Montserrat";
  overflow-x: hidden;
  -webkit-tap-highlight-color: transparent;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
