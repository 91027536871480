:root {
  --yellowish-orange: #ffae42;
  --red: #fe5454;
  --secondary-color: var(--yellowish-orange);
}

.text-fade-in {
  animation: fade 0.9s ease-in;
}

@keyframes fade {
  0% {
    opacity: 0;
  }
}

.name-and-photo-container {
  margin-top: 6%;
  display: flex;
  justify-content: center;
}

/* Name container stuff */

.name-container {
  margin-right: 18%;
  position: relative;
  top: 50px;
}

/* Profile picture stuff */

.my-picture {
  width: 250px;
  height: 250px;
  border-radius: 50%;
  margin: auto;
}

.noselect {
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently supported by Chrome, Edge, Opera and Firefox */
}

/* Mobile view */

@media (max-width: 950px) {
  .name-and-photo-container {
    margin-top: 2%;
  }
  .name-container {
    margin-right: 0;
  }
}
