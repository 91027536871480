.library-app-page-head {
  padding: 0 10% 0px 10%;
  display: flex;
}

.library-app-page-heading-container {
  width: 40%;
  height: 300px;
}

.library-app-page-title-container {
  display: flex;
}

.library-app-page-mockup-conatiner {
  margin-left: auto;
}

.library-app-tech-stack-container {
  display: flex;
  flex-wrap: wrap;
  margin: 0 40% 0 10%;
}

.library-app-features-container {
  margin: 0 5% 0 10%;
  display: flex;
  flex-wrap: wrap;
}

@media (max-width: 950px) {
  .library-app-page-mockup-conatiner {
    position: absolute;
    z-index: -999;
    visibility: collapse;
  }

  .library-app-page-heading-container {
    width: 100%;
  }

  .library-app-tech-stack-container {
    margin: 0 10% 0 10%;
  }

  .library-app-features-container {
    justify-content: center;
    margin: 0 5% 0 5%;
  }
}
